import React from "react"
import { FaStar } from "react-icons/fa"

export default function Review({ review }) {
  return (
    <div className="text-blue-900 border-b pb-10 mb-10">
      <p className="text-primary mb-3 text-xl">{review.author}</p>
      <div className="flex items-center space-x-2 mb-3 text-2xl text-orange-200">
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </div>
      <p className="leading-loose">{review.content}</p>
      {review.serviceType?.name && (
        <p className="font-bold text-primary text-sm mt-2">
          Service: {review.serviceType?.name}
        </p>
      )}
    </div>
  )
}
